import React from 'react';
import moment from 'moment';
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import styles from './ActiveTimerCell.module.scss';

export default function AccountsList(row, t) {
  const firstInImage = row.work_time_images?.find(image => image.type === 'in') || null;

  return <div className={styles.cellActiveTimerCell}>
    {row.active_timer?.id ? (
      <div  className={styles.cellActiveTimerCell__active}>
        {t('Yes')}
        <div className={styles.cellActiveTimerCell__tooltip}>
          <div className={styles.cellActiveTimerCell__working}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.98463 10.834L10.6526 8.47773C10.8329 8.36326 10.9231 8.20428 10.9231 8.0008C10.9231 7.79731 10.8329 7.6378 10.6526 7.52227L6.98463 5.16601C6.79569 5.03646 6.60189 5.02608 6.40324 5.1349C6.2046 5.2437 6.10528 5.41331 6.10528 5.64374V10.3563C6.10528 10.5867 6.2046 10.7563 6.40324 10.8651C6.60189 10.9739 6.79569 10.9635 6.98463 10.834ZM8.00141 16C6.89494 16 5.85491 15.79 4.88132 15.3701C3.90772 14.9502 3.06082 14.3803 2.34064 13.6604C1.62044 12.9405 1.05028 12.094 0.63017 11.1209C0.210057 10.1477 0 9.10788 0 8.00141C0 6.89494 0.209966 5.85491 0.629897 4.88132C1.04983 3.90772 1.61972 3.06082 2.33959 2.34064C3.05946 1.62044 3.90598 1.05028 4.87915 0.630171C5.8523 0.210058 6.89212 0 7.99859 0C9.10506 0 10.1451 0.209966 11.1187 0.629897C12.0923 1.04983 12.9392 1.61972 13.6594 2.33959C14.3796 3.05946 14.9497 3.90598 15.3698 4.87915C15.7899 5.8523 16 6.89212 16 7.99859C16 9.10506 15.79 10.1451 15.3701 11.1187C14.9502 12.0923 14.3803 12.9392 13.6604 13.6594C12.9405 14.3796 12.094 14.9497 11.1209 15.3698C10.1477 15.7899 9.10788 16 8.00141 16ZM8 14.7369C9.88071 14.7369 11.4737 14.0842 12.779 12.779C14.0842 11.4737 14.7369 9.88071 14.7369 8C14.7369 6.11929 14.0842 4.52631 12.779 3.22104C11.4737 1.91577 9.88071 1.26314 8 1.26314C6.11929 1.26314 4.52631 1.91577 3.22104 3.22104C1.91577 4.52631 1.26314 6.11929 1.26314 8C1.26314 9.88071 1.91577 11.4737 3.22104 12.779C4.52631 14.0842 6.11929 14.7369 8 14.7369Z" fill="#0087FF"/>
            </svg>
            {t('Working Time')}: <b>{String(Math.floor(row.active_timer.worked_minutes / 60)).padStart(2, '0')}:
            {String(row.active_timer.worked_minutes % 60).padStart(2, '0')}</b>
          </div>
          <div className={styles.cellActiveTimerCell__clockin}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.31571 9.95184L10.0182 9.2493L7.50029 6.73119V3.60057H6.50033V7.13647L9.31571 9.95184ZM7.00143 13.2672C6.12547 13.2672 5.30211 13.101 4.53136 12.7686C3.76059 12.4361 3.09014 11.9849 2.51999 11.4151C1.94984 10.8452 1.49846 10.175 1.16588 9.40457C0.833287 8.63416 0.666992 7.81098 0.666992 6.93502C0.666992 6.05906 0.833215 5.23571 1.16566 4.46495C1.4981 3.69419 1.94927 3.02373 2.51916 2.45359C3.08906 1.88343 3.75922 1.43206 4.52964 1.09947C5.30005 0.766881 6.12324 0.600586 6.99919 0.600586C7.87515 0.600586 8.69851 0.766809 9.46926 1.09925C10.24 1.4317 10.9105 1.88286 11.4806 2.45275C12.0508 3.02265 12.5022 3.69281 12.8347 4.46324C13.1673 5.23365 13.3336 6.05683 13.3336 6.93279C13.3336 7.80874 13.1674 8.6321 12.835 9.40285C12.5025 10.1736 12.0514 10.8441 11.4815 11.4142C10.9116 11.9844 10.2414 12.4357 9.47098 12.7683C8.70057 13.1009 7.87738 13.2672 7.00143 13.2672ZM7.00031 12.2672C8.47809 12.2672 9.73642 11.7478 10.7753 10.7089C11.8142 9.67002 12.3336 8.41168 12.3336 6.9339C12.3336 5.45613 11.8142 4.19779 10.7753 3.1589C9.73642 2.12001 8.47809 1.60057 7.00031 1.60057C5.52253 1.60057 4.2642 2.12001 3.22531 3.1589C2.18642 4.19779 1.66698 5.45613 1.66698 6.9339C1.66698 8.41168 2.18642 9.67002 3.22531 10.7089C4.2642 11.7478 5.52253 12.2672 7.00031 12.2672Z" fill="#999BA9"/>
            </svg>
            {t('Clock-In Time')}: <b>{moment(row.active_timer.started_at).format('HH:mm')}</b>
          </div>
          {firstInImage && (
              <Gallery>
                <Item
                  original={firstInImage.image}
                  thumbnail={firstInImage.image}
                  height={1}
                  width={1}
                  target="_blank"
                  className={styles.cellActiveTimerCell__images}
                >
                  {({ ref, open }) => (
                    <div>
                      <button 
                        ref={ref} 
                        onClick={open} 
                        className={styles.cellActiveTimerCell__button}
                      >
                        {t('Clock-In Photo')}
                      </button>
                    </div>
                  )}
                </Item>
              </Gallery>
            )}
        </div>
      </div>
    ) : (
      <div className={styles.cellActiveTimerCell__notactive}>{t('No')}</div>
    )}
  </div>
}